import React, { useState } from 'react';
import Button from 'components/Form/Button';
import {
  StyledPackages,
  StyledPackagesWrapper,
  StyledSubtitle,
  StyledPackagesAccordion,
  StyledPackage
} from './styled';
import { StyledWrapper } from 'assets/global/styled';
import MarkdownPreview from '@uiw/react-markdown-preview';

const Packages = ({ page, packages }) => {
  const {
    content3,
    content3Title,
    callToAction2,
    callToAction3
  } = page;
  const [packagesOpened, setPackagesOpened] = useState(false);

  return (
    <StyledPackages id="anchorService4">
      <StyledWrapper>
        <StyledPackagesWrapper>
          <StyledSubtitle>{content3Title}</StyledSubtitle>

          <MarkdownPreview source={content3} />

          <StyledPackagesAccordion
            className={`${packagesOpened ? 'opened' : ''}`}
          >
            {packages.map((p, index) => {
              return (
                <StyledPackage key={index}>
                  <span>
                    {p.title} | {p.subtitle}
                  </span>

                  <MarkdownPreview source={p.description2} />
                </StyledPackage>
              );
            })}
          </StyledPackagesAccordion>

          <Button
            style={{ justifyContent: 'center' }}
            type="button"
            callToAction={true}
            withIcon={true}
            variant={`${packagesOpened ? 'dark-outline' : 'dark'}`}
            rotateIcon={packagesOpened}
            onClick={() => setPackagesOpened(!packagesOpened)}
          >
            {`${packagesOpened ? callToAction2 : callToAction3}`}
          </Button>
        </StyledPackagesWrapper>
      </StyledWrapper>
    </StyledPackages>
  );
};

export default Packages;
