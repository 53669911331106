import styled from 'styled-components';
import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';

export const StyledPackages = styled.div`
  background-color: ${colors.gray50};
`;

export const StyledPackagesWrapper = styled.div`
  padding: 60px 0;
`;

export const StyledSubtitle = styled.h3`
  position: relative;
  margin: 0 0 1rem 0;
  padding: 20px 0;
  font-family: ${fonts.primary};
  font-size: 24px;
  font-weight: 600;
  color: ${colors.gray300};
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 2px;
    background-color: ${colors.gray900};
    content: '';
  }

  @media screen and (max-width: 980px) {
    font-size: 18px;
    font-weight: 600;
  }
`;

export const StyledPackagesAccordion = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2.3rem;
  margin-top: 2.3rem;
  margin-bottom: 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;

  max-height: 0;
  overflow: hidden;

  &.opened {
    max-height: 3000px;
    margin-bottom: 2.3rem;
    opacity: 1;
  }
`;

export const StyledPackage = styled.div`
  span {
    font-size: 18px;
    font-weight: 600;
  }

  li {
    font-size: 18px;
    font-weight: 300;
    color: ${colors.gray300};
  }
`;
