import styled from 'styled-components';
import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';

export const StyledSubContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
`;

export const StyledSubContentBoxShadow = styled.div`
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  padding: 32px;
  width: 50%;

  @media screen and (max-width: 960px) {
    margin-top: 10px;
    width: 100%;
  }
`;

export const StyledSubContentBoxShadowTitle = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;

  @media screen and (max-width: 960px) {
    font-size: 14px;
  }
`;

export const StyledSubContentBoxShadowComment = styled.div`
  margin-top: 30px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 120%;

  @media screen and (max-width: 960px) {
    font-size: 15px;
  }

  a {
    color: ${colors.red300};
  }
`;

export const StyledSubContentBoxNormal = styled.div`
  border-radius: 12px;
  padding-left: 50px;
  gap: 24px;
  width: 50%;

  @media screen and (max-width: 960px) {
    padding-left: 0px;
    margin-top: 30px;
    width: 100%;
  }
`;

export const StyledSubContentBoxNormalTitle = styled.div`
  margin-bottom: 10px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;

  @media screen and (max-width: 960px) {
    font-size: 16px;
  }
`;

export const StyledSubContentBoxNormalContent = styled.div`
  position: relative;
  padding-top: 10px;
  margin-top: 0px;
  font-family: ${fonts.primary};
  font-size: 14px;
  color: ${colors.gray300};

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 2px;
    background-color: ${colors.red300};
    content: '';
  }

  li {
    line-height: 3;
  }

  @media screen and (max-width: 960px) {
    font-size: 14px;
  }
`;
