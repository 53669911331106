import styled, { css } from 'styled-components';

export const StyledCTAWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
  padding: 3rem;
  margin: ${(props) => (props.margin ? props.margin : '30px 0')};
  a {
    width: fit-content;
  }

  background-color: black;
  background-repeat: no-repeat;
  background-position: center;

  ${(props) =>
    css`
      background-image: url(${props.background?.url});

      @media screen and (min-width: ${props.background?.width}px) {
        background-image: linear-gradient(
            90deg,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 1)
              calc((100% - ${props.background?.width}px) / 2),
            rgba(0, 0, 0, 0)
              calc(
                ((100% - ${props.background?.width}px) / 2) + 200px
              ),
            rgba(0, 0, 0, 0)
              calc(
                ((100% - ${props.background?.width}px) / 2) +
                  ${props.background?.width}px - 200px
              ),
            rgba(0, 0, 0, 1)
              calc(
                ((100% - ${props.background?.width}px) / 2) +
                  ${props.background?.width}px
              ),
            rgba(0, 0, 0, 1) 100%
          ),
          url(${props.background?.url});
      }
    `}
`;

export const StyledCTATitle = styled.h2`
  margin: 0;
  text-align: center;
  font-size: 32px;
  font-weight: 500;
  color: white;

  @media screen and (max-width: 980px) {
    font-size: 16px;
  }
`;
