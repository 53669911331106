import styled from 'styled-components';
import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';

export const StyledCustomMadeServiceBox = styled.div`
  &:nth-child(even) > div {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  &:nth-child(even) > div > div:nth-child(2) {
    justify-content: flex-start;
  }

  @media screen and (max-width: 960px) {
    &:nth-child(even) > div {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
  }
`;

export const StyledItemHeadGroup = styled.div`
  width: 50%;

  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;

export const StyledItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
`;

export const StyledItemHeadContainer = styled.div`
  overflow: hidden;
`;

export const StyledItemHeadNumber = styled.div`
  color: ${colors.red400};
  opacity: 0.16;
  margin-left: 0px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 140px;
  line-height: 1.1;

  @media screen and (max-width: 960px) {
    padding: 15px 0px;
    font-size: 80px;
  }
`;

export const StyledItemHeadText = styled.div`
  margin: -60px 0px 0px 90px;
  color: ${colors.gray900};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 120%;
  position: static;
  z-index: 1;

  @media screen and (max-width: 960px) {
    margin: -60px 0px 0px 70px;
    font-size: 24px;
  }
`;

export const StyledItemHeadComment = styled.div`
  position: relative;
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: ${colors.gray300};
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 38px;
  padding-top: 1rem;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 2px;
    background-color: ${colors.red300};
    content: '';
  }

  @media screen and (max-width: 960px) {
    margin-bottom: 1rem;
    font-size: 16px;
  }
`;

export const StyledIconContainerEnd = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;

  img {
    min-width: 300px;
    max-width: 300px;
    margin: 0;
  }

  @media screen and (max-width: 960px) {
    display: none;
  }
`;
