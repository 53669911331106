import React, { useMemo } from 'react';
import Helmet from 'react-helmet';
import ListHorizontalAnchorActions from 'components/ListHorizontalAnchorActions';
import Heading from 'templates/Services/Heading';
import OtherServices from 'components/OtherServices';
import ContactUsCTA from 'templates/Services/ContactUsCTA';
import ContactUs from 'components/ContactUs';
import CustomMade from 'components/CustomMade';
import { StyledWrapper } from 'assets/global/styled';
import { StyledSection } from './styled';
import Packages from './Packages';

const StyledContainer = (props) => {
  const { children, ...otherProps } = props;

  return (
    <StyledSection {...otherProps}>
      <StyledWrapper>{children}</StyledWrapper>
    </StyledSection>
  );
};

const Services = ({
  page,
  services,
  packages,
  anchorMenuItems,
  pageContext
}) => {
  const { contactForm, locale } = page;
  const { baseURL } = pageContext;

  const group = (array, n) =>
    [...Array(Math.ceil(array.length / n))].map((el, i) =>
      array.slice(i * n, (i + 1) * n)
    );

  const otherServicesGrouped = useMemo(
    () =>
      group(
        services
          .filter((service) => !service.slug)
          .sort((a, b) => a.order - b.order),
        3
      ),
    [services]
  );

  return (
    <React.Fragment>
      <StyledContainer>
        <Heading page={page} />
      </StyledContainer>

      <StyledContainer padding="0">
        <ListHorizontalAnchorActions
          param={anchorMenuItems}
          page={page}
        />
      </StyledContainer>

      <StyledContainer>
        <CustomMade
          page={page}
          pageContext={pageContext}
          services={services}
        />
      </StyledContainer>

      <StyledSection padding="0px" margin="0px">
        <Packages page={page} packages={packages} />
      </StyledSection>

      {otherServicesGrouped.map((otherServices, index) => {
        return (
          <>
            {index === 0 ? (
              <ContactUsCTA page={page} margin="0px" />
            ) : null}

            <StyledContainer key={index}>
              <OtherServices
                subtitle={index === 0 ? page.content1Subtitle : null}
                services={otherServices}
              />
            </StyledContainer>

            {index === 0 ? <ContactUsCTA page={page} /> : null}
          </>
        );
      })}

      <ContactUs
        id="contact"
        contactForm={contactForm}
        baseURL={baseURL}
        lang={locale}
        styledAsLp={true}
      />

      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context":"https://schema.org",
              "@type":"Organization",
              "name":"ateliware",
              "legalName":"ateliware",
              "url":"http://ateliware.com/br/services",
              "logo":"https://media.graphassets.com/gJsd71c5SGh1RyV8tIrV",
              "address":{
                "@type":"PostalAddress",
                "streetAddress":"Rua Pasteur, 463",
                "addressLocality":"Curitiba",
                "addressRegion":"Paraná",
                "postalCode":"80250-080",
                "addressCountry":"Brasil"
              },
              "contactPoint":{
                "@type":"ContactPoint",
                "contactType":"Contato",
                "telephone":"[+55 41 3010-2275]",
                "email":"hello@ateliware.com"
              },
              "sameAs":[
                "https://github.com/ateliware",
                "https://www.linkedin.com/company/ateliware/",
                "https://twitter.com/ateliware",
                "https://www.youtube.com/channel/UCxPjijyar2emdKLPpOrugOw",
                "https://www.meetup.com/ateliware/",
                "https://goo.gl/maps/gLPc35mF3js7rLnL8"
              ]
            }
          `}
        </script>
      </Helmet>
    </React.Fragment>
  );
};

export default Services;
