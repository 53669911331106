import styled from 'styled-components';
import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';

export const StyledHorizontalMenuContainer = styled.div`
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  list-style: none;
  column-gap: 5px;
  overflow-x: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledHorizontalMenuItem = styled.a`
  min-width: max-content;
  border: solid 1px black;
  border-radius: 25px;
  padding: 5px 12px;
  text-decoration: none;
  user-select: none;
  font-size: 14px;
  color: #777777;
  &:hover {
    border: solid 1px ${colors.red300};
    color: ${colors.red300};
    background: rgba(239, 0, 68, 0.08);
  }
`;

export const StyledComposeMenu = styled.div`
  display: flex;
  overflow: hidden;
`;

export const StyledArrow = styled.img`
  width: auto;
  height: 15px;
  align-self: center;
  margin-left: 15px;
  margin-bottom: 1rem;

  &.hidden {
    display: none;
  }
`;

export const StyledTitleContainer = styled.h6`
  font-size: 14px;
  font-weight: 400;
  color: ${colors.gray300};
  font-family: ${fonts.secondary};
  margin: 0 0 10px 0;

  &.hidden {
    display: none;
  }
`;
