import styled from 'styled-components';
import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';

export const StyledCustomMadeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const StyledHeadText = styled.h2`
  position: relative;
  margin: 0 0 1rem 0;
  padding: 20px 0;
  font-family: ${fonts.primary};
  font-size: 24px;
  font-weight: 600;
  color: ${colors.gray300};
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 2px;
    background-color: ${colors.gray900};
    content: '';
  }

  @media screen and (max-width: 980px) {
    font-size: 18px;
    font-weight: 600;
  }
`;

export const StyledHeadDescription = styled.div`
  p {
    font-size: 18px;
    font-weight: 300;
    color: ${colors.gray300};
    line-height: 1.8;
    font-family: ${fonts.secondary};

    strong {
      font-weight: 700;
    }

    @media screen and (max-width: 960px) {
      font-size: 15px;
    }
  }
`;

export const StyledHeadSubComment = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 38px;
`;
