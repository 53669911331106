import React from 'react';
import { Link } from 'gatsby';
import Button from 'components/Form/Button';
import { StyledCTAWrapper, StyledCTATitle } from './styled';

const ContactUsCTA = (props) => {
  const { page, ...otherProps } = props;
  const { introCallToAction, content2Image, callToAction } = page;

  return (
    <StyledCTAWrapper background={content2Image} {...otherProps}>
      <StyledCTATitle>{introCallToAction}</StyledCTATitle>

      <Button
        style={{ justifyContent: 'center' }}
        to="#contact"
        as={Link}
        type="button"
        callToAction={true}
        withIcon={true}
      >
        {callToAction}
      </Button>
    </StyledCTAWrapper>
  );
};

export default ContactUsCTA;
