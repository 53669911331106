import React from 'react';
import MarkdownPreview from '@uiw/react-markdown-preview';
import CustomMadeService from 'components/CustomMadeService';
import {
  StyledHeadText,
  StyledHeadDescription,
  StyledCustomMadeWrapper
} from './styled';

const CustomMade = ({ page, services }) => {
  const { content, contentSubtitle } = page;

  const services_list = services.filter(
    (service) => service.order <= 3
  );

  return (
    <>
      <StyledHeadText>{contentSubtitle}</StyledHeadText>
      <StyledHeadDescription>
        <MarkdownPreview source={content} />
      </StyledHeadDescription>

      <StyledCustomMadeWrapper>
        {services_list.map((service, key) => {
          const {
            order,
            title,
            altDescription,
            altIcon,
            title1,
            description1,
            title2,
            description2
          } = service;

          return (
            <CustomMadeService
              key={key}
              order={order}
              title={title}
              altDescription={altDescription}
              altIcon={altIcon}
              title1={title1}
              description1={description1}
              title2={title2}
              description2={description2}
            />
          );
        })}
      </StyledCustomMadeWrapper>
    </>
  );
};

export default CustomMade;
