import React from 'react';
import MarkdownPreview from '@uiw/react-markdown-preview';
import CustomMadeServiceSubCategorie from 'components/CustomMadeServiceSubCategorie';
import {
  StyledCustomMadeServiceBox,
  StyledItemContainer,
  StyledItemHeadGroup,
  StyledItemHeadContainer,
  StyledItemHeadNumber,
  StyledItemHeadText,
  StyledItemHeadComment,
  StyledIconContainerEnd
} from './styled';

const CustomMadeService = ({
  order,
  title,
  altDescription,
  altIcon,
  title1,
  description1,
  title2,
  description2
}) => {
  return (
    <StyledCustomMadeServiceBox id={`anchorService${order}`}>
      <StyledItemContainer>
        <StyledItemHeadGroup>
          <StyledItemHeadContainer>
            <StyledItemHeadNumber>0{order}</StyledItemHeadNumber>
            <StyledItemHeadText>{title}</StyledItemHeadText>
          </StyledItemHeadContainer>
          <StyledItemHeadComment>
            <MarkdownPreview source={altDescription} />
          </StyledItemHeadComment>
        </StyledItemHeadGroup>

        <StyledIconContainerEnd>
          <img src={altIcon.url} alt={altIcon.alt} />
        </StyledIconContainerEnd>
      </StyledItemContainer>

      <CustomMadeServiceSubCategorie
        title1={title1}
        description1={description1}
        title2={title2}
        description2={description2}
      />
    </StyledCustomMadeServiceBox>
  );
};

export default CustomMadeService;
