import React from 'react';
import MarkdownPreview from '@uiw/react-markdown-preview';
import {
  StyledSubContentContainer,
  StyledSubContentBoxShadow,
  StyledSubContentBoxShadowTitle,
  StyledSubContentBoxShadowComment,
  StyledSubContentBoxNormal,
  StyledSubContentBoxNormalTitle,
  StyledSubContentBoxNormalContent
} from './styled';

const CustomMadeServiceSubCategorie = ({
  title1,
  description1,
  title2,
  description2
}) => {
  return (
    <StyledSubContentContainer>
      <StyledSubContentBoxShadow>
        <StyledSubContentBoxShadowTitle>
          {title1}
        </StyledSubContentBoxShadowTitle>
        <StyledSubContentBoxShadowComment>
          <MarkdownPreview source={description1} />
        </StyledSubContentBoxShadowComment>
      </StyledSubContentBoxShadow>

      <StyledSubContentBoxNormal>
        <StyledSubContentBoxNormalTitle>
          {title2}
        </StyledSubContentBoxNormalTitle>
        <StyledSubContentBoxNormalContent>
          <MarkdownPreview source={description2} />
        </StyledSubContentBoxNormalContent>
      </StyledSubContentBoxNormal>
    </StyledSubContentContainer>
  );
};

export default CustomMadeServiceSubCategorie;
