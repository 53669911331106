import React from 'react';
import { graphql } from 'gatsby';
import Header from 'components/Header';
import Footer from 'components/Footer';
import SEO from 'components/SEO';
import Services from 'templates/Services';

const ServicesPage = ({
  data: {
    ateliware: {
      page,
      servicesV23,
      anchorMenuItems,
      packages,
      headerMenu,
      footerMenu
    }
  },
  pageContext
}) => {
  const { locale } = pageContext;
  const { seo } = page;

  return (
    <React.Fragment>
      <SEO
        meta_title={
          locale === 'pt'
            ? seo?.metaTitle
            : 'services | from ideation to development of digital products'
        }
        meta_description={
          locale === 'pt'
            ? seo?.metaDescription
            : "We work in all stages of the development of a digital product – from identification and validation to problem resolution. Discovery, product concept, web and mobile applications and API's."
        }
        og_title={
          locale === 'pt'
            ? seo?.ogTitle
            : 'services | from ideation to development of digital products'
        }
        og_description={
          locale === 'pt'
            ? seo?.ogDescription
            : "We work in all stages of the development of a digital product – from identification and validation to problem resolution. Discovery, product concept, web and mobile applications and API's."
        }
        og_type={seo?.ogType}
        og_image={seo?.ogImage?.url}
        lang={locale}
      />
      <Header menu={headerMenu} pageContext={pageContext} />
      <Services
        page={page}
        anchorMenuItems={anchorMenuItems}
        pageContext={pageContext}
        services={servicesV23}
        packages={packages}
      />
      <Footer
        footerMenu={footerMenu}
        pageContext={pageContext}
        privatePolicyTitle={page.contactForm.privacyPolicyTitle}
      />
    </React.Fragment>
  );
};

export const serviceQuery = graphql`
  query ServicePageQuery($idPage: ID!, $locale: [GCMS_Locale!]!) {
    ateliware {
      page(where: { id: $idPage }, locales: $locale) {
        id
        locale
        slug
        content
        contentSubtitle
        content1Subtitle
        seo(locales: pt) {
          metaTitle
          metaDescription
          ogTitle
          ogDescription
          ogType
          ogImage {
            url
          }
        }
        contactForm {
          title
          content
          nameField
          surnameField
          companyField
          emailField
          phoneField
          serviceOptionDefault
          serviceOption1
          serviceOption2
          serviceOption3
          serviceOption4
          aboutYouField
          budgetDefault
          budgetOption1
          budgetOption2
          budgetOption3
          sendButtonText
          formErrorMessage
          privacyPolicyTitle
          privacyPolicyText
          thankYouTitle
          thankYouContent
          backToSiteText
          toBlogText
        }
        title
        description
        callToAction
        introCallToAction
        content2Image {
          url
          alt
          width
        }
        callToAction
        content3
        content3Title
        callToAction2
        callToAction3
      }
      packages(locales: $locale) {
        title
        subtitle
        description2
      }
      servicesV23(locales: $locale) {
        title
        description
        altDescription
        slug
        alternativeSlug
        order
        altCallToAction
        altIcon {
          url
          alt
        }
        title1
        description1
        title2
        description2
      }
      anchorMenuItems(
        where: { page: "services-v23" }
        locales: $locale
      ) {
        id
        items
        page
        titleOfAnchorMenu
      }
      headerMenu: menu(where: { title: "header" }, locales: $locale) {
        locale
        callToAction
        menuItems {
          title
          slug
          locale
          goTo
          externalLink
          subMenuItems {
            title
            slug
            locale
            goTo
            externalLink
          }
        }
      }
      footerMenu: menu(where: { title: "footer" }, locales: $locale) {
        locale
        callToAction
        menuItems {
          title
          slug
          locale
          goTo
          externalLink
        }
      }
    }
  }
`;

export default ServicesPage;
