import styled from 'styled-components';

export const StyledHeading = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  a {
    margin-top: 40px;
    width: fit-content;

    @media screen and (max-width: 980px) {
      align-self: center;
    }
  }
`;
