import React from 'react';
import { StyledTitle } from '../styled';
import { StyledHeading } from './styled';
import MarkdownPreview from '@uiw/react-markdown-preview';
import Button from 'components/Form/Button';
import { Link } from 'gatsby';

const Heading = ({ page }) => {
  const { title, description, callToAction } = page;

  return (
    <StyledHeading>
      <StyledTitle>{title}</StyledTitle>

      <MarkdownPreview source={description} />

      <Button
        style={{ justifyContent: 'center' }}
        to="#contact"
        as={Link}
        type="button"
        callToAction={true}
        withIcon={true}
      >
        {callToAction}
      </Button>
    </StyledHeading>
  );
};

export default Heading;
