import React, { useEffect, useRef, useState } from 'react';
import IconRightArrow from 'assets/images/icons/right-chevron.svg';
import {
  StyledHorizontalMenuContainer,
  StyledHorizontalMenuItem,
  StyledArrow,
  StyledComposeMenu,
  StyledTitleContainer
} from './styled';

const ListHorizontalAnchorActions = ({ param, page }) => {
  const { locale } = page;
  const scrollRef = useRef(null);
  const [isHidden, setIsHidden] = useState(true);

  useEffect(() => {
    setIsHidden(!isOverflown(scrollRef.current));

    const handleResize = () => {
      setIsHidden(!isOverflown(scrollRef.current));
    };

    window.addEventListener('resize', handleResize);
  }, []);

  const isOverflown = ({
    clientWidth,
    clientHeight,
    scrollWidth,
    scrollHeight
  }) => {
    return scrollHeight > clientHeight || scrollWidth > clientWidth;
  };

  console.log(param);

  return (
    <>
      {param.map((item) => {
        return (
          <StyledTitleContainer
            className={`${isHidden ? 'hidden' : ''}`}
          >
            {item.titleOfAnchorMenu}
          </StyledTitleContainer>
        );
      })}
      <StyledComposeMenu>
        <StyledHorizontalMenuContainer ref={scrollRef}>
          {param.map((item) => {
            return item.items.map((anchor_link, key) => {
              return (
                <StyledHorizontalMenuItem
                  href={`#anchorService${key + 1}`}
                  key={key}
                >
                  {anchor_link}
                </StyledHorizontalMenuItem>
              );
            });
          })}
        </StyledHorizontalMenuContainer>
        <StyledArrow
          src={IconRightArrow}
          alt={'chevron right'}
          className={`${isHidden ? 'hidden' : ''}`}
        />
      </StyledComposeMenu>
    </>
  );
};

export default ListHorizontalAnchorActions;
